import React, { useEffect, useState } from "react";
import Auth from "../utils/auth/auth";
import { navigate } from "gatsby";
import authenticationService from "../utils/api/v1/authenticationService";
import scopes from "../utils/auth/scopes";
import { useQueryParam, StringParam } from "use-query-params";
import {
  AuthenticatingBox,
  LoginBox,
  LoginWrap,
  LoginButton,
  CustomInput,
  Avatar,
  LockIcon,
  CenterDiv,
} from "../styles/Layout/Index";

// typings
//! Fix typings in this component

// components
import HorizontalLoader from "../components/Loaders/HorizontalLoader";
import InnerContainer from "../components/Containers/InnerContainer";
import { Form, FormGroup } from "reactstrap";

const avatarLogin = require(`../assets/branding/isotipo.png`).default;

const auth = new Auth();

const AuthenticatingOverlay = () => {
  return (
    <AuthenticatingBox>
      <HorizontalLoader />
    </AuthenticatingBox>
  );
};

const Login = () => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [queryError, setQueryError] = useQueryParam("error", StringParam);
  const [redirect, setRedirect] = useQueryParam("redirect", StringParam);

  useEffect(() => {
    auth.isLoggedIn() && navigate("/offline-appointment/assisted-schedule/");
    scanForErrors();
  }, []);

  const scanForErrors = (): void => {
    switch (queryError) {
      case "NotLoggedIn":
        setError("Debes iniciar sesión");
        break;
    }
  };

  const enableToLogin = () => {
    return [
      (loginData["email"] || '').length > 0,
      (loginData["password"] || '').length > 0
    ].every(x => x);
  }

  const handleChange = (e: any): void => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const requestLogin = async () => {
    setError("");
    setAuthenticating(true);
    try {
      const authenticated = await authenticationService.login({
        ...loginData,
        scopes,
      });
      if (authenticated) {
        const authData = authenticated.data;
        const userData = authData["https://api.saludando.cl/jwt/claims"];

        /* check if user is superadmin */
        if (!userData.roles.includes("superadmin")) {
          setError("Este usuario no tiene permisos suficientes para esto");
          return;
        }

        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("token", authData["access_token"]);
        localStorage.setItem("refresh_token", authData["refresh_token"]);
        localStorage.setItem("user", JSON.stringify(authData));
        localStorage.setItem("auth_id", userData["auth_id"]);

        if (!redirect) {
          navigate("/offline-appointment/assisted-schedule/");
        } else {
          navigate("/offline-appointment/assisted-schedule/");
        }

        setAuthenticating(false);
      }
    } catch (e: any) {
      setError("Uh oh, usuario o contraseña incorrectos!");
    } finally {
      setAuthenticating(false);
    }
  };

  return (
    <LoginWrap>
      <InnerContainer max-width="1200px" noHorizontalPadding={true}>
        <LoginBox>
          {authenticating && <AuthenticatingOverlay />}
          <CenterDiv>
            <Avatar src={avatarLogin} alt="examedi-isotipo" />
            <h1 style={{ marginTop: 20 }}>Ingresa a tu cuenta</h1>
            <div style={{ marginTop: 30 }}>
              <p
                style={{
                  color: "var(--red)",
                  fontSize: 14,
                  lineHeight: "14px",
                  marginBottom: 10,
                }}
              >
                {error}
              </p>
            </div>
          </CenterDiv>

          <Form
            autoComplete="new-password"
            onSubmit={(e) => {
              e.preventDefault();
              requestLogin();
            }}
          >
            <FormGroup>
              <CustomInput
                type="email"
                autoComplete="new-password"
                placeholder="Email"
                value={loginData["email"]}
                name="email"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <CustomInput
                type="password"
                autoComplete="new-password"
                placeholder="Password"
                name="password"
                value={loginData["password"]}
                onChange={handleChange}
              />
            </FormGroup>
            <LoginButton type="submit" disabled={!enableToLogin()}>
              <LockIcon />
              <CenterDiv>Login</CenterDiv>
            </LoginButton>
          </Form>
          {/* <div style={{ marginTop: 20 }}>
            <Link
              to="/recover"
              style={{ color: "var(--richblue)", fontSize: 14 }}
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </div> */}
        </LoginBox>
      </InnerContainer>
    </LoginWrap>
  );
};

export default Login;
